<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<headerNav :isScrolled="isScrolled" :currentPath="currentPath">
			</headerNav>
			<div class="pd_banner">
				<div class="pd_banner_img">
					<img
						class="pd_banner_img_1"
						src="https://zy.metaera.media/assets/images/event/61.png"
						alt=""
					/>
					<img
						class="pd_banner_img_2"
						src="https://zy.metaera.media/assets/images/event/61.png"
						alt=""
					/>
					<div class="pd_banner_img_overlay"></div>
				</div>
				<div class="pd_banner_20">AGENDA</div>
				<div class="pd_banner_21">
					Polkadot Decoded Asia is the premier event in the Polkadot ecosystem,
					bringing together developers, investors, enthusiasts, and industry
					leaders to explore the latest developments in blockchain technology.
				</div>
			</div>
			<div class="pd_agenda_line">
				<img src="https://zy.metaera.media/assets/images/event/62.png" alt="" />
			</div>
			<div class="pd_agenda_search_top">
				<div class="pd_agenda_search">
					<div class="pd_agenda_search_title">SEARCH</div>
					<div class="pd_agenda_search_content">
						<dic class="pd_agenda_search_btn" @click="search()">
							<img
								src="https://zy.metaera.media/assets/images/event/64.png"
								alt=""
							/>
						</dic>
						<input
							class="pd_agenda_search_text"
							placeholder="Search session"
							type="text"
							v-model="keyword"
							@blur="search()"
							@keyup.enter="search()"
						/>
					</div>
					<div class="pd_agenda_search_days_title pd_agenda_search_title"
						>DAYS</div
					>
					<div class="pd_agenda_search_days"
						><div
							class="pd_agenda_search_days_item"
							@click="selectDay(0)"
							:class="{ pd_agenda_search_days_item_sel: selDay == 0 }"
							>All Days</div
						>
						<div
							class="pd_agenda_search_days_item"
							@click="selectDay(1)"
							:class="{ pd_agenda_search_days_item_sel: selDay == 1 }"
							>Mon Sep 16th</div
						>
						<div
							class="pd_agenda_search_days_item"
							@click="selectDay(2)"
							:class="{ pd_agenda_search_days_item_sel: selDay == 2 }"
							>Tue Sep 17th</div
						>
					</div>
				</div>
			</div>
			<div class="pd_agenda_list">
				<div class="pd_agenda_list_left">
					<div
						class="pd_agenda_list_item"
						v-for="(item, index) in this.resultArray"
						:key="index"
					>
						<div>
							<div
								class="pd_agenda_list_date"
								:class="{
									pd_agenda_list_date_first: index == 0,
								}"
							>
								{{ item.date }}</div
							>
							<div
								class="pd_agenda_list_subItem"
								v-for="(subItem, index) in item.items"
								:key="index"
								:class="{ pd_agenda_list_subItem_first: index == 0 }"
							>
								<div class="pd_agenda_list_subItem_top">
									<span class="pd_agenda_list_subItem_top_time">{{
										subItem.time
									}}</span>
									<span class="pd_agenda_list_subItem_top_gmt"
										>GMT +8（{{ subItem.min }}）</span
									>
								</div>
								<div class="pd_agenda_list_subItem_center">
									<div
										class="pd_agenda_list_subItem_tag"
										v-if="subItem.tag.length > 0"
									>
										{{ subItem.tag }}
									</div>
									<div class="pd_agenda_list_subItem_title">
										{{ subItem.title }}
									</div>
								</div>
								<div
									class="pd_agenda_list_subItem_bottom"
									v-if="subItem.hosts.length > 0"
								>
									<div
										class="pd_agenda_list_subItem_host"
										v-for="(host, index) in subItem.hosts"
										:key="index"
									>
										<div class="pd_agenda_list_subItem_host_top">
											<img
												v-if="host.voice == 1"
												class="pd_agenda_list_subItem_host_voice"
												src="https://zy.metaera.media/assets/images/event/63.png"
												alt=""
											/>
											<span>{{ host.name }}</span>
										</div>
										<div class="pd_agenda_list_subItem_host_bottom">
											{{ host.company }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pd_agenda_list_right">
					<div class="pd_agenda_search">
						<div class="pd_agenda_search_title">SEARCH</div>
						<div class="pd_agenda_search_content">
							<dic class="pd_agenda_search_btn" @click="search()">
								<img
									src="https://zy.metaera.media/assets/images/event/64.png"
									alt=""
								/>
							</dic>
							<input
								class="pd_agenda_search_text"
								placeholder="Search session"
								type="text"
								v-model="keyword"
								@blur="search()"
								@keyup.enter="search()"
							/>
						</div>
						<div class="pd_agenda_search_days_title pd_agenda_search_title"
							>DAYS</div
						>
						<div class="pd_agenda_search_days">
							<div
								class="pd_agenda_search_days_item"
								@click="selectDay(0)"
								:class="{ pd_agenda_search_days_item_sel: selDay == 0 }"
								>All Days</div
							>
						</div>
						<div class="pd_agenda_search_days">
							<div
								class="pd_agenda_search_days_item"
								@click="selectDay(1)"
								:class="{ pd_agenda_search_days_item_sel: selDay == 1 }"
								>Mon Sep 16th</div
							>
							<div
								class="pd_agenda_search_days_item"
								@click="selectDay(2)"
								:class="{ pd_agenda_search_days_item_sel: selDay == 2 }"
								>Tue Sep 17th</div
							>
						</div>
					</div>
				</div>
			</div>
			<bottom></bottom>
			<div class="demo" style="height: 50px"></div>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import bottom from "./pdbottom.vue";
import pdintroduceVue from "./pdintroduce.vue";
import people from "./pdpeople.vue";

export default {
	name: "",
	components: {
		headerNav,
		bottom,
		people,
		pdintroduceVue,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			isScrolled: true,
			selDay: 0,
			keyword: "",
			currentPath: "/polkadot2024_agenda",
			list: [
				{
					date: "Monday, Sep 16th",
					items: [
						{
							time: "9:00 AM - 9:55 AM",
							min: "55 Min",
							tag: "",
							title: "Registration",
							hosts: [],
						},
						{
							time: "9:55 AM - 10:00 AM",
							min: "5 Min",
							tag: "Opening Remarks",
							title: "Opening Remarks",
							hosts: [
								{
									voice: 0,
									name: "Helena Wang",
									company: "Organizer, Polkadot Decoded Asia",
								},
							],
						},
						{
							time: "10:00 AM - 10:15 AM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Striking a Balance: How to Maintain Decentralization and Compliance in Web3",
							hosts: [
								{
									voice: 0,
									name: "Chrissy Hill",
									company: "COO (Interim) & CLO, Parity ",
								},
							],
						},
						{
							time: "10:15 AM - 10:30 AM",
							min: "15 Min",
							tag: "Keynote",
							title: "Looking Forward of the Fusion of AI and Web3",
							hosts: [
								{
									voice: 0,
									name: "Kun Peng",
									company:
										"Founder at Stanford Blockchain Accelerator (SBA) & Founder at Blockchain Application Stanford Summit (BASS) ",
								},
							],
						},
						{
							time: "10:30 AM - 10:45 AM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Mass Adoption of Astar: Vision to Onboard Billion Users Onchain",
							hosts: [
								{
									voice: 0,
									name: "Sota Watanabe",
									company: "Founder, Astar",
								},
							],
						},
						{
							time: "10:45 AM - 11:30 AM",
							min: "45 Min",
							tag: "Panel",
							title: "VC Views",
							hosts: [
								{
									voice: 1,
									name: "Joanna Liang",
									company: "JSquare",
								},
								{
									voice: 0,
									name: "Rui",
									company: "HashKey Capital",
								},
								{
									voice: 0,
									name: "Isaac",
									company: "SNZ Capital",
								},
								{
									voice: 0,
									name: "Figo",
									company: "IOSG Ventures",
								},
								{
									voice: 0,
									name: "Terry Culver",
									company: "Digital Finance Group (DFG)",
								},
							],
						},
						{
							time: "11:30 AM - 11:45 AM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Introducing Polkadot's Ecosystem Fund Scytale: The Reasons Polkadot Is an Attractive Ecosystem",
							hosts: [
								{
									voice: 0,
									name: "Mark Cachia",
									company: "CIO, Scytale Digital",
								},
							],
						},
						{
							time: "11:45 AM - 12:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "TBD",
							hosts: [
								{
									voice: 0,
									name: "Jason",
									company: "Block chain Lead, Zama.AI",
								},
							],
						},
						{
							time: "12:00 PM - 1:00 PM",
							min: "60 Min",
							tag: "",
							title: "Lunch",
							hosts: [],
						},
						{
							time: "1:00 PM - 1:15 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Advancing NFTs in the Polkadot Ecosystem",
							hosts: [
								{
									voice: 0,
									name: "Charu Sethi",
									company: "CMO, Unique Network",
								},
							],
						},
						{
							time: "1:15 PM - 1:30 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"CognideX: Leveraging the Synergy of Blockchain and AI Technologies in Transforming the Data Marketplace",
							hosts: [
								{
									voice: 0,
									name: "James Pang",
									company:
										"Associate Professor, Co-Director, NUS Business Analytics Center",
								},
							],
						},
						{
							time: "1:30 PM - 1:45 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"The Media’s Responsibility and Role in Blockchain Technology",
							hosts: [
								{
									voice: 0,
									name: "David Wachsman",
									company: "Chief Executive Officer, Wachsman",
								},
							],
						},
						{
							time: "1:45 PM - 2:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "How to Build Polkadot Chinese Developer Community",
							hosts: [
								{
									voice: 0,
									name: "Tracy",
									company: "Community Manager, OneBlock+",
								},
							],
						},
						{
							time: "2:00 PM - 2:45 PM",
							min: "45 Min",
							tag: "Panel",
							title: "Polkadot's Viral Global Community",
							hosts: [
								{
									voice: 1,
									name: "Rhee",
									company: "Moondance Lab",
								},
								{
									voice: 0,
									name: "Tracy",
									company: "OneBlock+",
								},
								{
									voice: 0,
									name: "Kristen",
									company: "PolkaWorld",
								},
								{
									voice: 0,
									name: "Dr. Cao",
									company: "AIWeb3",
								},
								{
									voice: 0,
									name: "Patricia Arro",
									company: "Polkadot SEA|OpenGuild",
								},
							],
						},
						{
							time: "2:45 PM - 3:30 PM",
							min: "45 Min",
							tag: "",
							title: "Coffee Break & Networking",
							hosts: [],
						},
						{
							time: "3:30 PM - 3:45 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"SubWallet: Building a Polkadot Use Case Aggregation Interface for Mass Adoption",
							hosts: [
								{
									voice: 0,
									name: "DJ Hà Trang",
									company: "Lead Research, Subwallet",
								},
							],
						},
						{
							time: "3:45 PM - 4:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Modularizing DApps: Transitioning from Appchains to a New Architecture",
							hosts: [
								{
									voice: 0,
									name: "Denny",
									company: "Co-Founder, Itering Tech",
								},
							],
						},
						{
							time: "4:0 PM - 4:15 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "StellaSwap: DeFi Abstraction with Polkadot",
							hosts: [
								{
									voice: 0,
									name: "Aziz Zainuddin",
									company: "Co-Founder, StellaSwap",
								},
							],
						},
						{
							time: "4:15 PM - 5:00 PM",
							min: "45 Min",
							tag: "Panel",
							title: "VC and Liquidity",
							hosts: [
								{
									voice: 1,
									name: "Wilfred Daye",
									company: "Samara Alpha Management",
								},
								{
									voice: 0,
									name: "Ciara Sun",
									company: "C² Ventures",
								},
								{
									voice: 0,
									name: "Benson",
									company: "OKX Venture",
								},
								{
									voice: 0,
									name: "Edward",
									company: "HTX Venture",
								},
								{
									voice: 0,
									name: "JT Law",
									company: "IVC",
								},
							],
						},
						{
							time: "5:00 PM - 5:30 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Polkadot JAM",
							hosts: [
								{
									voice: 0,
									name: "Gavin Wood",
									company: "Founder, Polkadot",
								},
							],
						},
						{
							time: "5:30 PM - 5:45 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Think Smart - Building Intelligent DApps in Polkadot",
							hosts: [
								{
									voice: 0,
									name: "James Bayly",
									company: "COO, SubQuery Network",
								},
							],
						},
						{
							time: "5:45 PM - 6:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Building the Next Ten Billion Dollar AI Ecosystem with Polkadot and Bittensor",
							hosts: [
								{
									voice: 0,
									name: "Brendan Playford",
									company: "Co-Founder, Masa",
								},
							],
						},
						{
							time: "6:00 PM - 6:05 PM",
							min: "5 Min",
							tag: "Event Conclusion",
							title: "Announce the Agenda of Day 2 and Polkadot Hackathon",
							hosts: [
								{
									voice: 0,
									name: "Helena Wang",
									company: "Organizer, Polkadot Decoded Asia",
								},
							],
						},
					],
				},
				{
					date: "Tuesday, Sep 17th",
					items: [
						{
							time: "9:00 AM - 9:55 AM",
							min: "55 Min",
							tag: "",
							title: "Registration",
							hosts: [],
						},
						{
							time: "9:55 AM - 10:00 AM",
							min: "5 Min",
							tag: "Opening Keynote",
							title: "ME Supporting Polkadot",
							hosts: [
								{
									voice: 0,
									name: "MetaEra",
									company: "",
								},
							],
						},
						{
							time: "10:00 AM - 10:15 AM",
							min: "15 Min",
							tag: "Keynote",
							title: "The Future of Funding on Polkadot",
							hosts: [
								{
									voice: 0,
									name: "Seraya Takahashi",
									company: "Ecosystem Development, Web3 Foundation",
								},
							],
						},
						{
							time: "10:15 AM - 10:30 AM",
							min: "15 Min",
							tag: "Keynote",
							title: "Raising a New $100m Polkadot Ecosystem Fund",
							hosts: [
								{
									voice: 0,
									name: "Max Rebol/Mario Altenburger",
									company: "CEO/CFO, Harbour Industrial Capital",
								},
							],
						},
						{
							time: "10:30 AM - 10:45 AM",
							min: "15 Min",
							tag: "Keynote",
							title: "Redefining Fundraising",
							hosts: [
								{
									voice: 0,
									name: "Luca von Wyttenbach",
									company: "Co-Founder, Polimec",
								},
							],
						},
						{
							time: "10:45 AM - 11:30 AM",
							min: "45 Min",
							tag: "Panel",
							title: "Polkadot Core Pillar",
							hosts: [
								{
									voice: 1,
									name: "Zoé Meckbach",
									company: "Polkadot Head Ambassador",
								},
								{
									voice: 0,
									name: "Derek Yoo",
									company: "Moonsong Labs",
								},
								{
									voice: 0,
									name: "James Bayly",
									company: "Subquery Network",
								},
								{
									voice: 0,
									name: "Martin Quensel",
									company: "Centrifuge",
								},
								{
									voice: 0,
									name: "Kyle Song",
									company: "Google Cloud",
								},
							],
						},
						{
							time: "11:30 AM - 11:45 AM",
							min: "15 Min",
							tag: "Keynote",
							title: "Mythica: Future of Gaming",
							hosts: [
								{
									voice: 0,
									name: "John Linden",
									company: "CEO, Mythical Game",
								},
							],
						},
						{
							time: "11:45 AM - 12:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Gaming Governance",
							hosts: [
								{
									voice: 0,
									name: "Alberto Viera",
									company: "DevReal Lead, Moonbeam",
								},
							],
						},
						{
							time: "12:00 PM - 1:00 PM",
							min: "60 Min",
							tag: "",
							title: "Lunch",
							hosts: [],
						},
						{
							time: "1:00 PM - 1:15 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Is Restaking a Meme? Leveraging Bifrost Infrastructure to Extend Polkadot Consensus",
							hosts: [
								{
									voice: 0,
									name: "Tyrone Pan",
									company: "Dev Relation, Bifrost",
								},
							],
						},
						{
							time: "1:15 PM - 1:30 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Bringing Embedded Wallets to Polkadot",
							hosts: [
								{
									voice: 0,
									name: "Nino Kutnjak",
									company: "CPO, Apillion",
								},
							],
						},
						{
							time: "1:30 PM - 1:45 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "CESS and DePIN: A Synergistic Approach",
							hosts: [
								{
									voice: 0,
									name: "Nicholas Zaldastani",
									company: "Co-Founder, CESS Network",
								},
							],
						},
						{
							time: "1:45 PM - 2:30 PM",
							min: "45 Min",
							tag: "Panel",
							title: "Media Partners",
							hosts: [
								{
									voice: 1,
									name: "David Wachsman",
									company: "Wachsman",
								},
								{
									voice: 0,
									name: "Arthur",
									company: "BlockTempo (Taiwan)",
								},
								{
									voice: 0,
									name: "Micky Sun",
									company: "PANews (Mainland China)",
								},
								{
									voice: 0,
									name: "Andi Ain Jung",
									company: "Blockmedia (Korea)",
								},
								{
									voice: 0,
									name: "Ryo Sakai",
									company: "CoinPost (Japan)",
								},
							],
						},
						{
							time: "2:30 PM - 2:45 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Marine Biodiversity in Crypto? Dolphins, Whales and Other Companions Have Entered the Crypto World",
							hosts: [
								{
									voice: 0,
									name: "Catherine Bischoff",
									company: "CEO, Sovereign Nature Initiative",
								},
							],
						},
						{
							time: "2:45 PM - 3:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Seamless Blockchain Development Experience with Polkadot SDK & Pop CLI",
							hosts: [
								{
									voice: 0,
									name: "Tin Chung",
									company: "Software Engineer, Rouge| OpenGuild",
								},
							],
						},
						{
							time: "3:00 PM - 3:45 PM",
							min: "15 Min",
							tag: "",
							title: "Coffee Break & Networking",
							hosts: [],
						},
						{
							time: "3:45 PM - 4:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Building a Polkadot Developer Docs Hub",
							hosts: [
								{
									voice: 0,
									name: "Nicolas Hussein",
									company: " DevRel Engineer, Papermoon",
								},
							],
						},
						{
							time: "4:00 PM - 4:15 PM",
							min: "15 Min",
							tag: "Keynote",
							title:
								"Acala 2.0 -- Building the Liquidity Layer of Web3 Finance",
							hosts: [
								{
									voice: 0,
									name: "Yuzhu",
									company: "BD & Marketing APAC, Acala",
								},
							],
						},
						{
							time: "4:15 PM - 5:00 PM",
							min: "45 Min",
							tag: "Panel",
							title: "POS, POW, Cloud and Node Operation Busines",
							hosts: [
								{
									voice: 1,
									name: "Emerick Mary",
									company: "Foundry",
								},
								{
									voice: 0,
									name: "James Liu",
									company: "Alibaba Cloud",
								},
								{
									voice: 0,
									name: "Brendan Playford",
									company: "Masa",
								},
								{
									voice: 0,
									name: "Ghan Vashishtha",
									company: "Zeeve",
								},
								{
									voice: 0,
									name: "Aki Balogh",
									company: "dlcBTC",
								},
							],
						},
						{
							time: "5:00 PM - 5:15 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "A Way to Do Quadratic Funding in the Polkadot Ecosystem",
							hosts: [
								{
									voice: 0,
									name: "Yongfeng Li",
									company: "CEO, OpenSquare",
								},
							],
						},
						{
							time: "5:15 PM - 5:30 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Exploring Opportunities with Subscan's Open Platform",
							hosts: [
								{
									voice: 0,
									name: "Yakio Liu",
									company: "Marketing Lead, Subscan",
								},
							],
						},
						{
							time: "5:30 PM - 5:45 PM",
							min: "15 Min",
							tag: "Keynote",
							title: " Transform Your Parachain Launch with Perfuse",
							hosts: [
								{
									voice: 0,
									name: "Ghan Vashishtha",
									company: "Co-Founder & CTO, Zeeve",
								},
							],
						},
						{
							time: "5:45 PM - 6:00 PM",
							min: "15 Min",
							tag: "Keynote",
							title: "Bridging Communities and Markets through Opengov",
							hosts: [
								{
									voice: 0,
									name: "Luna Hao",
									company: "Head of Marketing, Jinse",
								},
							],
						},
						{
							time: "6:00 PM - 6:05 PM",
							min: "5 Min",
							tag: "Closing Remarks",
							title: "Closing Remarks",
							hosts: [
								{
									voice: 0,
									name: "Helena Wang",
									company: "Organizer, Polkadot Decoded Asia",
								},
							],
						},
					],
				},
			],
			resultArray: [],
		};
	},
	created() {
		this.search();
	},
	mounted() {
		this.keyword = "";
		this.selDay = 0;
		this.search();
	},
	methods: {
		search() {
			let resultArray = [];
			for (let index = 0; index < this.list.length; index++) {
				const element = this.list[index];
				let bigItem = {};
				bigItem.date = element.date;
				const bigArray = [];
				if (this.selDay == 0 || this.selDay == index + 1) {
					for (let num = 0; num < element.items.length; num++) {
						const item = element.items[num];
						if (this.containsKeyword(item)) {
							bigArray.push(item);
						}
					}
				}
				if (bigArray.length > 0) {
					bigItem.items = bigArray;
					resultArray.push(bigItem);
				}
			}

			if (resultArray.length > 0) {
				this.resultArray = resultArray;
			} else {
				this.resultArray = this.list;
			}
		},
		containsKeyword(item) {
			if (this.keyword == undefined || this.keyword.length == 0) {
				return true;
			}

			const keyword = this.keyword.trim().toLowerCase();

			// 检查 tag 和 title
			if (
				item.tag.toLowerCase().includes(keyword) ||
				item.title.toLowerCase().includes(keyword)
			) {
				return true;
			}

			// 检查 hosts 数组中的 name 和 company
			for (const host of item.hosts) {
				if (
					host.name.toLowerCase().includes(keyword) ||
					host.company.toLowerCase().includes(keyword)
				) {
					return true;
				}
			}
			return false;
		},
		selectDay(index) {
			this.selDay = index;
			this.search();
		},
		handleScroll(event) {
			this.isScrolled = true;
		},
		sendEmail() {
			const email = "event@metaera.hk";
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;

	background: #f7f8f9;
}
</style>

<!-- banner -->
<style scoped>
.pd_banner {
	margin-top: 80px;
	width: 100vw;
	height: calc(100vw * (1770 / 3840));
}

.pd_banner_img {
	width: 100%;
	height: auto;
}

.pd_banner_img_1 {
	display: flex;
}

.pd_banner_img_2 {
	display: none;
}
.pd_banner_img img {
	width: 100%;
	height: 100%;
}
.pd_banner_bottom {
	position: absolute;
	margin-left: 5%;
	margin-right: 5%;
	margin-top: -40px;
	height: 40px;
	width: 90%;
	background: white;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	z-index: 10;
	display: none;
}
.pd_banner_20 {
	margin-top: 180px;
	position: absolute;
	left: calc(100vw * 40 / 1280);
	right: calc(100vw * 40 / 1280);
	top: calc(100vw * 40 / 1280);
	font-family: DINPro-Bold;
	font-size: calc(100vw * 60 / 1280);
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	text-align: center;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}

.pd_banner_21 {
	margin-top: 180px;
	position: absolute;
	left: calc(100vw * 190 / 1280);
	right: calc(100vw * 190 / 1280);
	top: calc(100vw * 150 / 1280);
}
.pd_banner_21,
.pd_banner_21 span {
	font-family: D-DIN;
	font-size: calc(100vw * 20 / 1280);
	text-align: center;
	letter-spacing: 0em;
	line-height: 1.2;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.pd_banner_21 span {
	text-decoration: underline;
}
.pd_banner_img_overlay {
	position: absolute;
	top: 80px;
	left: 0;
	width: 100%;
	height: calc(100vw * (1770 / 3840));
	opacity: 0.6;
	background: black;
}
@media screen and (max-width: 900px) {
	.pd_banner_img_overlay {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		height: calc(100vw * 1770 / 3840);
		opacity: 0.6;
		background: black;
	}
	.pd_banner {
		margin-top: 50px;
		height: calc(100vw * 1770 / 3840);
	}
	.pd_banner_img {
		width: 100%;
		height: 100%;
	}

	.pd_banner_img_1 {
		display: none;
	}

	.pd_banner_img_2 {
		display: flex;
	}
	.pd_banner_bottom {
		display: flex;
	}

	.pd_banner_20 {
		position: absolute;
		margin-top: 50px;
		left: calc(100vw * 50 / 375);
		right: calc(100vw * 50 / 375);
		top: calc(100vw * 20 / 375);
		font-family: DINPro-Bold;
		font-size: calc(100vw * 20 / 375);
		font-weight: bold;
		line-height: normal;
		letter-spacing: 0em;
		text-align: center;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}

	.pd_banner_21 {
		position: absolute;
		margin-top: 50px;
		left: calc(100vw * 30 / 375);
		top: calc(100vw * 80 / 375);
	}

	.pd_banner_21,
	.pd_banner_21 span {
		font-family: D-DIN;
		font-size: calc(100vw * 12 / 375);
		text-align: center;
		line-height: 1.2;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
}
</style>

<style scoped>
.pd_agenda_line {
	width: 100vw;
	height: calc(100vw * (456 / 3840));
	display: block;
}

.pd_agenda_line img {
	width: 100vw;
	height: auto;
}

.pd_agenda_list {
	margin-top: calc(100vw * (-360 / 3840));
	min-height: 100vh;
	display: flex;
}

.pd_agenda_list_left {
	width: 70%;
}

.pd_agenda_list_right {
	width: 30%;
	display: block;
}

.pd_agenda_list_item {
	margin-left: 10%;
	margin-right: 30px;
}

.pd_agenda_list_date {
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: 60px;
	text-align: left;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #000000;
	margin-bottom: 30px;
}

.pd_agenda_list_date_first {
	color: #ffffff;
}

.pd_agenda_list_subItem {
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	margin-bottom: 30px;
	border-radius: 10px;
	opacity: 1;

	background: #f2f3f9;

	/* 投影 */
	box-shadow: 0px 12px 31px 0px rgba(71, 71, 71, 0.1);
}

.pd_agenda_list_subItem_first {
	background: white;
}

.pd_agenda_list_subItem_top {
	/* margin-left: 20px; */
	/* margin-top: 20px; */
}

.pd_agenda_list_subItem_top_time {
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0em;
	margin-right: 10px;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_agenda_list_subItem_top_gmt {
	font-family: D-DIN;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	/* 正文色/正文辅助色 */
	color: #767676;
}

.pd_agenda_list_subItem_center {
	margin-top: 20px;
	display: flex;
}

.pd_agenda_list_subItem_tag {
	flex-shrink: 0; /* 禁止缩小 */
	margin-bottom: 0px;
	max-height: 26px;
	margin-right: 10px;
	border-radius: 6px;
	opacity: 1;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 6px;
	padding-bottom: 6px;
	box-sizing: border-box;
	border: 1px solid #333333;
	font-family: DINPro-Bold;
	font-size: 12px;
	font-weight: bold;
	line-height: 12px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_agenda_list_subItem_title {
	margin-top: -6px;
	font-family: DINPro-Bold;
	font-size: 30px;
	font-weight: bold;
	line-height: 34px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_agenda_list_subItem_bottom {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap; /* 允许换行 */
	gap: 10px; /* 项目之间的间距 */
}

.pd_agenda_list_subItem_host {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 20px;
	margin-top: 15px;
}

.pd_agenda_list_subItem_host_top {
	display: inline-block; /* 根据内容自动调整宽度 */
	padding-left: 10px;
	padding-right: 10px;
	height: 26px;
	border-radius: 6px;
	opacity: 1;
	background: #000000;
}
.pd_agenda_list_subItem_host_top img {
	width: 14px;
	height: 14px;
	margin-right: 6px;
}

.pd_agenda_list_subItem_host_top span {
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_agenda_list_subItem_host_bottom {
	display: inline-block; /* 根据内容自动调整宽度 */
	margin-top: 10px;
	font-family: D-DIN;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	text-align: left;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
</style>

<style scoped>
.pd_agenda_search {
	padding: 20px;
	margin-top: 88px;
	width: 80%;
	margin-left: 0%;
	border-radius: 10px;
	opacity: 1;

	background: #ffffff;

	/* 投影 */
	box-shadow: 0px 12px 31px 0px rgba(71, 71, 71, 0.1);
}

.pd_agenda_search_title {
	font-family: DINPro-Bold;
	font-size: 20px;
	font-weight: bold;
	line-height: 20px;
	text-align: left;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_agenda_search_content {
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 15px;
	height: 50px;
	border-radius: 10px;
	opacity: 1;

	box-sizing: border-box;
	border: 1px solid #333333;
}

.pd_agenda_search_btn img {
	/* margin-top: 15px; */
	margin-right: 15px;
	width: 20px;
	height: 20px;
}
.pd_agenda_search_text {
	margin-top: 12px;
	border-color: white;
	border-width: 0px;
	width: 70%;
}

.pd_agenda_search_days_title {
	margin-top: 20px;
}

.pd_agenda_search_days {
	display: flex;
	margin-top: 15px;
	justify-content: space-between;
}

.pd_agenda_search_days_item {
	width: 45%;
	font-family: DINPro-Bold;
	font-size: 18px;
	font-weight: bold;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
	height: 44px;
	border-radius: 10px;
	opacity: 1;
	align-content: center;
	box-sizing: border-box;
	border: 1px solid #333333;
}

.pd_agenda_search_days_item_sel {
	background: #333333;
	color: #ffffff;
}

.pd_agenda_search_top {
	display: none;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.pd_agenda_line {
		display: none;
	}

	.pd_agenda_list {
		margin-top: 20px;
	}
	.pd_agenda_list_left {
		width: 100%;
	}
	.pd_agenda_list_item {
		margin-left: 5%;
		margin-right: 5%;
	}

	.pd_agenda_list_right {
		width: 30%;
		display: none;
	}
	.pd_agenda_list_date_first {
		color: #000000;
	}
	.pd_agenda_search_top {
		display: contents;
	}

	.pd_agenda_search {
		padding: 5%;
		margin-top: 20px;
		width: 80%;
		margin-left: 5%;
	}

	.pd_agenda_list_date {
		font-size: 20px;
	}
	.pd_agenda_list_subItem {
		margin-bottom: 20px;
	}

	.pd_agenda_list_subItem_center {
		display: inline-block;
	}
	.pd_agenda_list_subItem_tag {
		display: inline-block;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.pd_agenda_list_subItem_title {
		font-size: 22px;
	}

	.pd_agenda_list_subItem_host {
		margin-top: 15px;
	}

	.pd_agenda_search_days_item {
		width: 30%;
	}

	.pd_agenda_list_subItem_host_top span {
		font-size: 14px;
	}
}
</style>
